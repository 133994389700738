<template>
  <register-layout>
    <form novalidate data-test="form" @submit.prevent="passwordReset">
      <h1 class="register__title">
        {{ $t("c.reset_password.title") }}
      </h1>
      <div class="register__subtitle mb-0">
        {{ $t("c.reset_password.subtitle_line_01") }}
      </div>
      <div class="register__subtitle mt-2">
        {{ $t("c.reset_password.subtitle_line_02") }}
      </div>
      <FormControl
        required
        class="mb-4"
        :label="$t('label.email')"
        :error="errorMessageFor('email')"
      >
        <Input
          v-model="email"
          type="email"
          autocomplete="off"
          :has-errors="hasErrorFor('email')"
          :placeholder="$t('label.enter_email')"
        />
      </FormControl>
      <FormControl
        required
        class="mb-4"
        :label="$t('label.new_password')"
        :error="errorMessageFor('password')"
      >
        <InputPassword
          v-model="password"
          autocomplete="off"
          :has-errors="hasErrorFor('password')"
          :placeholder="$t('label.password')"
        />
      </FormControl>
      <FormControl
        required
        class="mb-4"
        :label="$t('c.reset_password.confirm_new_password')"
        :error="errorMessageFor('password_confirmation')"
      >
        <InputPassword
          v-model="passwordConfirmation"
          autocomplete="off"
          :has-errors="hasErrorFor('password_confirmation')"
          :placeholder="$t('label.password')"
        />
      </FormControl>
      <Button type="submit" :is-loading="loading">
        {{ $t("c.reset_password.save_new_password") }}
      </Button>
      <p class="register__text">
        <Anchor :link="{ name: 'auth.login' }">
          {{ $t("c.reset_password.return_to_login") }}
        </Anchor>
      </p>
    </form>
    <template slot="info">
      <OrgTipsSlider />
    </template>
  </register-layout>
</template>

<script>
import { jwtToken } from "@/utils/helpers/functions";
import {
  FormControl,
  Input,
  InputPassword,
  Button,
  Anchor,
} from "@/design-system";
import Services from "../services.js";
import { OrgTipsSlider } from "@/v2/new-design-system";
import { pathOr, isEmpty, propOr } from "ramda";

export default {
  name: "RecoverPassword",
  components: {
    FormControl,
    Input,
    InputPassword,
    Button,
    Anchor,
    OrgTipsSlider,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    email: "",
    password: "",
    passwordConfirmation: "",
    errors: {},
    loading: false,
  }),
  methods: {
    errorMessageFor(item) {
      return pathOr("", [item, 0], this.errors);
    },
    hasErrorFor(item) {
      return !isEmpty(propOr("", item, this.errors));
    },
    async passwordReset() {
      this.loading = true;

      this.errors = {};

      const params = {
        email: this.email,
        token: this.token,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      };

      try {
        const response = await Services.resetPassword(params);

        jwtToken.set(response);

        this.$router.push({ name: "app.index" });
      } catch (e) {
        const email = e.errors.email;

        // ToDo: Replace error message with error code

        if (email) {
          e.errors.email = email.map((message) =>
            message === "This password reset token is invalid."
              ? this.$t("c.reset_password.error_expired_token")
              : message
          );
        }

        this.errors = e.errors;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  p {
    margin-bottom: 0;
  }
}
</style>
