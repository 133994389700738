<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <form novalidate data-test="resturant_info_form" @submit.prevent="validate">
    <FormControl
      required
      class="mb-4"
      data-test="restaurant_name_input"
      :label="$t('c.signup.label_restaurant_name')"
      :error="errorMessageFor('restaurant')"
    >
      <Input
        v-model.trim="formData.restaurant"
        type="text"
        autofocus
        :placeholder="$t('c.signup.label_restaurant_name_placeholder')"
        :has-errors="hasErrorFor('restaurant')"
        @change="saveOnStore()"
      />
    </FormControl>
    <FormControl
      required
      class="mb-4"
      data-test="outlets_input"
      :label="$t('c.signup.label_total_outlets')"
      :error="errorMessageFor('outlets')"
    >
      <Select
        v-model="formData.outlets"
        :has-errors="hasErrorFor('outlets')"
        @change="saveOnStore()"
      >
        <option :value="null" selected></option>
        <option
          v-for="outlet in outletsList"
          :key="outlet.key"
          :value="outlet.key"
        >
          {{ outlet.key }}
        </option>
      </Select>
    </FormControl>
    <FormControl
      required
      class="mb-4"
      data-test="country_input"
      :label="$t('c.signup.label_country')"
      :error="errorMessageFor('country')"
    >
      <Select
        v-model="formData.country"
        :has-errors="hasErrorFor('country')"
        @change="saveOnStore()"
      >
        <option :value="null" selected></option>
        <optgroup>
          <option value="AE">United Arab Emirates</option>
        </optgroup>
        <optgroup>
          <option
            v-for="country in countries"
            :key="country.abbr"
            :value="country.abbr"
          >
            {{ country.name }}
          </option>
        </optgroup>
      </Select>
    </FormControl>
    <FormControl
      required
      class="mb-4"
      data-test="lead_source_input"
      :label="$t('c.signup.label_lead_source')"
      :error="errorMessageFor('source')"
    >
      <Select
        v-model="formData.source"
        :has-errors="hasErrorFor('source')"
        @change="saveOnStore()"
      >
        <option :value="null" selected></option>
        <option
          v-for="source in sourceList"
          :key="source.key"
          :value="source.key"
        >
          {{ source.key }}
        </option>
      </Select>
    </FormControl>
    <Button type="submit" data-test="resturant_step_continue_button">
      {{ $t("c.signup.continue") }}
    </Button>
  </form>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import countries from "@/utils/enums/countries";
import { FormControl, Input, Select, Button } from "@/design-system";
import { isNilOrEmpty } from "ramda-extension";
import { isNil, isEmpty, pathOr, propOr } from "ramda";

export default {
  name: "RestaurantInfo",
  components: {
    FormControl,
    Input,
    Select,
    Button,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    countries: countries,
    outletsList: [
      { key: "1 outlet" },
      { key: "2 - 5 outlets" },
      { key: "6 - 10 outlets" },
      { key: "10+ outlets" },
    ],
    sourceList: [
      { key: "Google" },
      { key: "Instagram" },
      { key: "Other restaurant" },
      { key: "Facebook" },
      { key: "Friend" },
      { key: "Radio" },
      { key: "Other" },
    ],
    validations: {
      restaurant: "",
      outlets: "",
      country: "",
      source: "",
    },
    utm: {
      medium: "",
      source: "",
      campaign: "",
    },
  }),
  mounted() {
    if (!isNil(this.$route.query.utm_medium)) {
      this.utm.medium = this.$route.query.utm_medium;
    }

    if (!isNil(this.$route.query.utm_source)) {
      this.utm.source = this.$route.query.utm_source;
    }

    if (!isNil(this.$route.query.utm_campaign)) {
      this.utm.campaign = this.$route.query.utm_campaign;
    }
  },
  methods: {
    saveOnStore() {
      const objSignup = this.formData;
      delete objSignup.password;
      window.localStorage.setItem("signupData", JSON.stringify(objSignup));
    },
    errorMessageFor(item) {
      if (isNil(this.errors)) return;
      return (
        pathOr("", [item, 0], this.errors) ||
        pathOr("", [item], this.validations)
      );
    },
    hasErrorFor(item) {
      return (
        !isEmpty(propOr("", item, this.errors)) ||
        !isEmpty(propOr("", item, this.validations))
      );
    },
    validate() {
      const fields = ["restaurant", "outlets", "country", "source"];
      fields.forEach((item) => {
        this.validations[item] = "";
        if (isNilOrEmpty(this.formData[item])) {
          this.validations[item] = this.$t(`c.signup.required_${item}`);
        }
      });

      if (
        isNilOrEmpty(this.formData.restaurant) ||
        isNilOrEmpty(this.formData.outlets) ||
        isNilOrEmpty(this.formData.country) ||
        isNilOrEmpty(this.formData.source)
      ) {
        return;
      }

      this.goToAccountSetup();
    },
    goToAccountSetup() {
      this.trackEvent();
      this.$emit("selectStep", "account");
    },
    trackEvent() {
      this.$analytics.trackEvent("Added restaurant info", {
        event_category: "authentication",
        restaurant_name: this.formData.restaurant,
        outlets_count: this.formData.outlets,
        country: this.formData.country,
        lead_source: this.formData.source,
        utm_medium: this.utm.medium,
        utm_source: this.utm.source,
        utm_campaign: this.utm.campaign,
      });
    },
  },
};
</script>
