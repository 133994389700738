<template>
  <register-layout v-if="!hasToken">
    <form novalidate data-test="form" @submit.prevent="passwordRequest">
      <h1 class="register__title">
        {{ $t("c.forgot_password.title") }}
      </h1>
      <div class="register__subtitle">
        {{ $t("c.forgot_password.subtitle") }}
      </div>
      <FormControl
        required
        class="mb-4"
        :label="$t('label.email')"
        :error="errorMessageFor('email')"
      >
        <Input
          id="email"
          v-model="email"
          type="email"
          :has-errors="hasErrorFor('email')"
          :placeholder="$t('label.enter_email')"
        />
      </FormControl>
      <Button
        type="submit"
        class="btn btn-block btn-primary"
        :is-loading="loading"
      >
        {{ $t("c.forgot_password.reset_password") }}
      </Button>
      <p class="register__text">
        <Anchor :link="{ name: 'auth.login' }">
          {{ $t("c.forgot_password.return_to_login") }}
        </Anchor>
      </p>
    </form>
    <template slot="info">
      <OrgTipsSlider />
    </template>
  </register-layout>
</template>

<script>
import { FormControl, Input, Button, Anchor, Toast } from "@/design-system";
import Services from "../services.js";
import { OrgTipsSlider } from "@/v2/new-design-system";
import { jwtToken } from "@/utils/helpers/functions";
import { pathOr, isEmpty, propOr } from "ramda";
export default {
  name: "ForgotPassword",
  components: {
    FormControl,
    Input,
    Button,
    Anchor,
    OrgTipsSlider,
  },
  data: () => ({
    email: "",
    errors: {},
    hasToken: false,
    loading: false,
  }),
  beforeMount() {
    if (jwtToken.get()) {
      this.$router.push({
        name: "app.index",
      });
      this.hasToken = true;
    }
  },
  methods: {
    errorMessageFor(item) {
      return pathOr("", [item, 0], this.errors);
    },
    hasErrorFor(item) {
      return !isEmpty(propOr("", item, this.errors));
    },
    async passwordRequest() {
      this.errors = {};
      this.loading = true;
      try {
        await Services.forgotPassword({ email: this.email });
        new Toast().create({
          type: "success",
          text: this.$t("message.link_has_been_sent"),
        });
        this.email = "";
      } catch (e) {
        this.errors = e.errors;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
