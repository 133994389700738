export default class FacebookPixel {
  constructor(appId) {
    this.appId = appId;

    this.init(document, "script", "facebook-jssdk");
  }

  init(d, s, id) {
    window.fbAsyncInit = () => {
      // eslint-disable-next-line
      FB.init({
        appId: this.appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v2.12",
      });
    };

    let js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }
}
