<template>
  <nav class="steps">
    <a
      v-for="(step, index) in steps"
      :key="index"
      href="#"
      class="steps__item"
      data-test="tab"
      :class="{
        'steps__item--active': currentStep === step,
      }"
      @click.prevent="$emit('selectStep', step)"
    >
      <span>{{ $t(`c.signup.step_${step}`) }}</span>
    </a>
  </nav>
</template>

<script>
export default {
  name: "Steps",
  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.steps {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0;
  counter-reset: step;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 14px;
    background: #fff;
    text-decoration: none;
    counter-increment: step;

    @media (max-width: 440px) {
      flex-direction: column;

      span {
        margin-top: 5px;
      }
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &:before {
      content: counter(step);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #eff2fd;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #687086;
      font-weight: 600;
      font-size: 13px;
      margin-right: 11px;
      font-style: normal;
      line-height: 1rem;
    }

    span {
      font-size: 14px;
      line-height: 1;
      color: #000;
    }

    &--active {
      &:before {
        color: #fff;
        background: #1258ff;
      }

      span {
        font-weight: 600;
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    z-index: 0;
    background: #d8dff2;
    transform: translate(0, -50%);
  }
}
</style>
