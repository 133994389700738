





















import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  onMounted,
  onBeforeMount,
} from "@vue/composition-api";
import { AtomText } from "@/v2/new-design-system";
import { AtomTextTypeEnum } from "@/v2/new-design-system/atom/text/type.enum";
import Form from "../components/form/Index.vue";
import Testimonials from "../components/Testimonials.vue";
import { t } from "@/i18n";
// @ts-ignore
import { jwtToken } from "@/utils/helpers/functions";
const css = bemBuilder("signup-page");
export default defineComponent({
  name: "Signup",
  components: {
    AtomText,
    Form,
    Testimonials,
  },
  setup(_, context) {
    const hasToken = ref(false);
    const utm = ref<Record<string, any>>({
      medium: "",
      source: "",
      campaign: "",
    });
    onBeforeMount(() => {
      if (jwtToken.get()) {
        context.root.$router.push({
          name: "app.index",
        });
        hasToken.value = true;
      }
    });
    onMounted(() => {
      if (context.root.$route.query.utm_medium) {
        utm.value.medium = context.root.$route.query.utm_medium;
      }
      if (context.root.$route.query.utm_source) {
        utm.value.source = context.root.$route.query.utm_source;
      }
      if (context.root.$route.query.utm_campaign) {
        utm.value.campaign = context.root.$route.query.utm_campaign;
      }
      // @ts-ignore
      context.root.$analytics.trackEvent("Started sign up", {
        event_category: "authentication",
        utm_medium: utm.value.medium,
        utm_source: utm.value.source,
        utm_campaign: utm.value.campaign,
      });
    });
    return {
      css,
      t,
      AtomTextTypeEnum,
      hasToken,
      utm,
    };
  },
});
