<template>
  <div>
    <Steps
      :steps="steps"
      :current-step="currentStep"
      @selectStep="selectStep"
    />
    <component
      :is="currentStep"
      :form-data="formData"
      :errors="errors"
      :loading-submit="loadingSubmit"
      @selectStep="selectStep"
      @submitted="submitForm"
    />
    <p class="register__text">
      {{ $t("c.signup.already_have_account") }}
      <Anchor :link="{ name: 'auth.login' }">
        {{ $t("label.login") }}
      </Anchor>
    </p>
  </div>
</template>

<script>
import Auth from "@/api/auth";
import AuthConstructor from "@/constructors/Auth";
import Steps from "./Steps.vue";
import Restaurant from "./RestaurantStep.vue";
import Account from "./AccountStep.vue";
import FacebookPixel from "@/plugins/analytics/trackers/FacebookPixel";
import { Anchor, Toast } from "@/design-system";
import { isNil } from "ramda";
import { isValidEmail } from "@/v2/util/validator/email";

export default {
  name: "Form",
  components: {
    Steps,
    Restaurant,
    Account,
    Anchor,
  },
  data: () => ({
    steps: ["restaurant", "account"],
    currentStep: "restaurant",
    formData: new AuthConstructor().normalizeObject(),
    errors: {},
    loadingSubmit: false,
    utm: {
      medium: "",
      source: "",
      campaign: "",
    },
  }),
  mounted() {
    if (window.localStorage.getItem("signupData")) {
      this.formData = JSON.parse(window.localStorage.getItem("signupData"));
    }

    if (!isNil(this.$route.query.email)) {
      this.formData.email = this.$route.query.email;
      window.localStorage.setItem("signupData", JSON.stringify(this.formData));
    }

    if (!isNil(this.$route.query.utm_medium)) {
      this.utm.medium = this.$route.query.utm_medium;
    }

    if (!isNil(this.$route.query.utm_source)) {
      this.utm.source = this.$route.query.utm_source;
    }

    if (!isNil(this.$route.query.utm_campaign)) {
      this.utm.campaign = this.$route.query.utm_campaign;
    }
  },
  methods: {
    selectStep(tab) {
      tab === "restaurant"
        ? (this.currentStep = tab)
        : (this.currentStep = "account");
    },
    getQueryString() {
      const search = window.location.search.substring(1);

      if (!search) return {};

      return JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
    },
    preSubmitValidator(formData) {
      let preSubmitErrors = {};

      if (!formData.firstName?.trim()) {
        preSubmitErrors["firstName"] = [
          this.$t("c.signup.formErrors.first_name_required"),
        ];
      }
      if (!formData.lastName?.trim()) {
        preSubmitErrors["lastName"] = [
          this.$t("c.signup.formErrors.last_name_required"),
        ];
      }
      if (formData.mobile?.trim().length < 5) {
        preSubmitErrors["mobile"] = [
          this.$t("c.signup.formErrors.phone_length_error"),
        ];
      }
      if (!formData.mobile?.trim()) {
        preSubmitErrors["mobile"] = [
          this.$t("c.signup.formErrors.phone_number_required"),
        ];
      }
      if (!formData.email?.trim()) {
        preSubmitErrors["email"] = [
          this.$t("c.signup.formErrors.email_required"),
        ];
      }
      if (!isValidEmail(formData.email?.trim())) {
        preSubmitErrors["email"] = [
          this.$t("c.signup.formErrors.email_valid_error"),
        ];
      }
      if (!formData.password?.trim()) {
        preSubmitErrors["password"] = [
          this.$t("c.signup.formErrors.password_required"),
        ];
      }
      if (formData.password?.trim().length < 8) {
        preSubmitErrors["password"] = [
          this.$t("c.signup.formErrors.password_length_short_error"),
        ];
      }
      if (formData.password?.trim().length > 50) {
        preSubmitErrors["password"] = [
          this.$t("c.signup.formErrors.password_length_long_error"),
        ];
      }

      this.errors = preSubmitErrors;
    },
    async submitForm() {
      const formData = new AuthConstructor(this.formData).toCreatePayload();

      // Pre-submit validator
      this.preSubmitValidator(this.formData);

      if (Object.keys(this.errors).length) {
        return;
      }

      const payload = {
        ...this.getQueryString(),
        ...formData,
      };

      this.loadingSubmit = true;

      try {
        const res = await Auth.createTenantAccount(payload);

        new FacebookPixel(process.env.VUE_APP_FACEBOOK_ID);

        this.accountCreated(res.hash);
      } catch (error) {
        this.errors = new AuthConstructor(error.errors).normalizeObject();

        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: this.$t("message.request_failure"),
        });
      } finally {
        this.loadingSubmit = false;
      }
    },
    accountCreated(hash) {
      this.$router.push({
        name: "auth.account-setup",
        params: {
          hash: hash,
        },
        query: {
          utm_medium: this.utm.medium,
          utm_source: this.utm.source,
          utm_campaign: this.utm.campaign,
          from_signup: true,
        },
      });
    },
  },
};
</script>
