<template>
  <div class="testimonial">
    <h2 class="testimonial__title">
      {{ $t("c.signup.testimonial.title") }}
    </h2>
    <div v-for="(testimonial, index) in testimonials" :key="index">
      <div v-if="currentTestimonial === testimonial" class="testimonial__info">
        <h3 class="testimonial__text">
          {{ $t(`c.signup.testimonial.${testimonial}.text`) }}
        </h3>
        <p class="testimonial__author">
          {{ $t(`c.signup.testimonial.${testimonial}.author`) }}
        </p>
      </div>
    </div>
    <div class="testimonial__logos">
      <a
        v-for="testimonial in testimonials"
        :key="testimonial"
        href="#"
        class="testimonial__logos-item"
        :class="{
          'testimonial__logos-item--active': currentTestimonial === testimonial,
        }"
        @click.prevent="currentTestimonial = testimonial"
      >
        <img :src="`/img/testimonial/logo-${testimonial}.png`" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Testimonials",
  data: () => ({
    testimonials: [
      "kaak_almanara",
      "leap_nation",
      "mantoushe",
      "nabz",
      "wingsters",
    ],
    currentTestimonial: "kaak_almanara",
  }),
};
</script>

<style lang="scss" scoped>
.testimonial {
  &__info {
    min-height: 170px;
    transform: translate(0, 0);
    animation: fade-up 0.6s 1 cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  @keyframes fade-up {
    from {
      opacity: 0;
      transform: translate(0, 20px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.5;
    color: #fff;
    margin-bottom: 50px;
  }

  &__text {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: 0.01em;
    color: #fff;

    &:before,
    &:after {
      content: "“";
    }
  }

  &__author {
    margin: 15px 0 0;
    color: #687086;
  }

  &__logos {
    display: flex;
    overflow: auto;

    &-item {
      position: relative;
      display: block;
      min-width: 50px;
      width: 50px;
      height: 50px;
      margin: 10px 5px;
      border-radius: 100%;
      text-decoration: none;
      border: solid 4px #010e2b;
      background-color: #fff;
      box-shadow: 0 0 0 0 #1258ff;
      transition: all 0.2s ease-out;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 80%;
        max-height: 80%;
        transform: translate(-50%, -50%);
      }

      &:hover,
      &:focus,
      &--active {
        box-shadow: 0 0 0 4px #1258ff;
      }
    }
  }
}
</style>
