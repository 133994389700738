import Base from "./Base";

/** Constructor to normalize outsider data */
class TenantAccount extends Base {
  /**
   * Class Constuctor.
   *
   * @param {Object} tenantAccount
   */
  constructor(tenantAccount = {}) {
    const keys = {
      restaurant: ["restaurant_name", "restaurantName", "restaurant"],
      outlets: ["total_stores", "totalStores", "outlets"],
      country: ["country"],
      source: ["lead_source", "leadSource", "source"],
      firstName: ["fname", "f_name", "first_name", "firstName"],
      lastName: ["lname", "l_name", "last_name", "lastName"],
      countryCode: ["country_code", "countryCode"],
      mobile: ["phone_number", "phoneNumber", "mobile"],
      email: ["email"],
      password: ["password"],
    };

    super(keys, tenantAccount);
  }

  /**
   * Build payload to create a resource based in the API specs.
   *
   * @return {Object}
   */
  toCreatePayload() {
    return {
      restaurant_name: this.restaurant,
      total_stores: this.outlets,
      country: this.country,
      lead_source: this.source,
      fname: this.firstName,
      lname: this.lastName,
      country_code: this.countryCode,
      phone_number: this.mobile,
      email: this.email,
      password: this.password,
    };
  }

  /**
   * Build an object to normalize data.
   *
   * @return {Object}
   */
  normalizeObject() {
    return {
      restaurant: this.restaurant,
      outlets: this.outlets,
      country: this.country,
      source: this.source,
      firstName: this.firstName,
      lastName: this.lastName,
      countryCode: this.countryCode,
      mobile: this.mobile,
      email: this.email,
      password: this.password,
    };
  }
}

export default TenantAccount;
