import Api from "@/api/index";

export default {
  forgotPassword(params) {
    return Api.post("/api/v3/auth/forgot-password", params);
  },
  resetPassword(params) {
    return Api.post("/api/v3/auth/password-reset", params);
  },
};
