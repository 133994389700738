<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <form novalidate @submit.prevent="submitForm">
    <div class="row">
      <div class="col-sm-6">
        <FormControl
          required
          class="mb-4"
          data-test="first-name-input"
          :label="$t('c.signup.label_first_name')"
          :error="errorMessageFor('firstName')"
        >
          <Input
            v-model.trim="formData.firstName"
            type="text"
            :has-errors="hasErrorFor('firstName')"
            @change="saveOnStore()"
          />
        </FormControl>
      </div>
      <div class="col-sm-6">
        <FormControl
          required
          class="mb-4"
          data-test="last-name-input"
          :label="$t('c.signup.label_last_name')"
          :error="errorMessageFor('lastName')"
        >
          <Input
            v-model.trim="formData.lastName"
            type="text"
            :has-errors="hasErrorFor('lastName')"
            @change="saveOnStore()"
          />
        </FormControl>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-5">
        <FormControl
          required
          class="mb-4"
          data-test="country-code-select"
          :label="$t('c.signup.label_country_code')"
          :error="errorMessageFor('countryCode')"
        >
          <Select
            v-model="formData.countryCode"
            :has-errors="hasErrorFor('countryCode')"
            @change="saveOnStore()"
          >
            <option :value="null" selected></option>
            <option v-for="code in codeList" :key="code.key" :value="code.key">
              {{ code.value }}
            </option>
          </Select>
        </FormControl>
      </div>
      <div class="col-xs-12 col-sm-8 col-md-8 col-lg-7">
        <FormControl
          required
          class="mb-4"
          data-test="phone-number-input"
          :label="$t('c.signup.label_mobile')"
          :error="errorMessageFor('mobile')"
        >
          <Input
            v-model.trim="formData.mobile"
            type="tel"
            mask="############"
            :has-errors="hasErrorFor('mobile')"
            @change="saveOnStore()"
          />
        </FormControl>
      </div>
    </div>
    <FormControl
      required
      class="mb-4"
      data-test="email-input"
      :label="$t('c.signup.label_email')"
      :error="errorMessageFor('email') || invalidEmailErrorMessage"
    >
      <Input
        v-model.trim="formData.email"
        type="email"
        :has-errors="hasErrorFor('email') || Boolean(invalidEmailErrorMessage)"
        @change="saveOnStore()"
      />
    </FormControl>
    <FormControl
      required
      class="mb-4"
      data-test="password-input"
      :label="$t('c.signup.label_password')"
      :error="errorMessageFor('password')"
    >
      <InputPassword
        v-model.trim="formData.password"
        :has-errors="hasErrorFor('password')"
      />
    </FormControl>
    <div class="row">
      <div class="col-sm-6 mb-3">
        <Button
          class="button--outline-white"
          type="button"
          data-test="back"
          @click.prevent="$emit('selectStep', 'restaurant')"
        >
          {{ $t("c.signup.back") }}
        </Button>
      </div>
      <div class="col-sm-6 mb-3">
        <Button type="submit" :is-loading="loadingSubmit">
          {{ $t("c.signup.create_my_account") }}
        </Button>
      </div>
    </div>
    <p class="register__text">
      {{ $t("c.signup.agree_terms_and_privacy") }}
      <br />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.chatfood.io/terms-use"
      >
        {{ $t("c.signup.terms_of_service") }}
      </a>
      &
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.chatfood.io/privacy-policy"
      >
        {{ $t("c.signup.privacy_policy") }}
      </a>
    </p>
  </form>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import countries from "@/utils/enums/countries";
import {
  FormControl,
  Input,
  InputPassword,
  Select,
  Button,
} from "@/design-system";
import {
  compose,
  uniq,
  pluck,
  reject,
  isNil,
  isEmpty,
  pathOr,
  propOr,
} from "ramda";
import { isValidEmail } from "@/v2/util/validator/email";

export default {
  name: "AccountSetup",
  components: {
    FormControl,
    Input,
    InputPassword,
    Select,
    Button,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: null,
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    countries: countries,
    invalidEmailErrorMessage: null,
  }),
  computed: {
    codeList() {
      const list = compose(
        reject((o) => isNil(o) || isEmpty(o)),
        uniq,
        pluck("code")
      )(this.countries);

      list.sort((a, b) => a.localeCompare(b, "en"));

      return list.map((o) => ({ key: o, value: `+${o}` }));
    },
  },
  watch: {
    formData: {
      handler(val) {
        if (isNil(val.country)) return;

        if (isNil(val.countryCode)) {
          const country = this.countries.find(
            (obj) => obj.abbr === this.formData.country
          );
          this.formData.countryCode = country.code;
          this.saveOnStore();
        }
      },
      immediate: true,
    },
  },
  methods: {
    saveOnStore() {
      const objSignup = { ...this.formData };
      delete objSignup.password;
      window.localStorage.setItem("signupData", JSON.stringify(objSignup));
    },
    errorMessageFor(item) {
      if (isNil(this.errors)) return;
      return pathOr("", [item, 0], this.errors);
    },
    hasErrorFor(item) {
      return !isEmpty(propOr("", item, this.errors));
    },
    submitForm() {
      this.$emit("submitted");
    },
  },
};
</script>
