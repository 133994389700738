<template>
  <div class="account-setup">
    <Icon icon="chatfood-logo-new" :size="160" />
    <div v-if="!error" class="text-center" data-test="progress-bar">
      <p class="account-setup__text account-setup__text--first">
        {{ $t("message.we_are_preparing_your_account") }}
      </p>
      <p class="account-setup__text account-setup__text--second">
        {{ $t("message.wait_a_second") }}
      </p>
      <div class="account-setup__bar"></div>
    </div>
    <div v-else class="text-center" data-test="error">
      <h5 class="d-flex align-items-center justify-content-center m-0">
        <Icon icon="times-circle" :size="24" color="#dc3545" />
        <span class="ml-2">{{ $t("label.oops") }}</span>
      </h5>
      <p class="mt-2 mb-0">{{ $t("message.request_failure") }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bootstrap } from "@/utils/helpers/functions";
import { getActiveBusiness } from "@/utils/mixins";
import AuthConstructor from "@/constructors/Auth";
import { Icon } from "@/design-system";
import { isNil } from "ramda";

export default {
  name: "AccountSetup",
  components: {
    Icon,
  },
  mixins: [getActiveBusiness],
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    organizationData: new AuthConstructor().normalizeObject(),
    error: false,
    fromSignup: false,
    utm: {
      medium: "",
      source: "",
      campaign: "",
    },
  }),
  mounted() {
    if (window.localStorage.getItem("signupData")) {
      this.organizationData = JSON.parse(
        window.localStorage.getItem("signupData")
      );
    }

    if (!isNil(this.$route.query.from_signup)) {
      this.fromSignup = true;
    }

    if (!isNil(this.$route.query.utm_medium)) {
      this.utm.medium = this.$route.query.utm_medium;
    }

    if (!isNil(this.$route.query.utm_source)) {
      this.utm.source = this.$route.query.utm_source;
    }

    if (!isNil(this.$route.query.utm_campaign)) {
      this.utm.campaign = this.$route.query.utm_campaign;
    }

    this.performMagicLogin();
  },
  methods: {
    ...mapActions({ login: "auth/login" }),
    async performMagicLogin() {
      try {
        await this.attemptLoginWith({ hash: this.hash });
      } catch (e) {
        this.error = true;
      }
    },
    async attemptLoginWith(credentials) {
      const token = await this.login(credentials);

      await bootstrap(token, this.$store.dispatch, {
        gates: this.$gates,
        intercom: this.$intercom,
        hotjar: this.$hotjar,
        analytics: this.$analytics,
        urlParams: {},
      });

      this.trackEvent();

      this.$router.replace({
        name: "app.index",
        params: {
          isSignup: "welcome",
        },
      });
    },
    trackEvent() {
      const objTrack = {
        event_category: "authentication",
        business_id: this.activeBusiness.id,
        business_name: this.activeBusiness.name,
      };

      // Update objTrack when comes from SignUp page
      if (this.fromSignup) {
        (objTrack.restaurant_name = this.organizationData.restaurant),
          (objTrack.outlets_count = this.organizationData.outlets),
          (objTrack.country = this.organizationData.country),
          (objTrack.lead_source = this.organizationData.source),
          (objTrack.utm_medium = this.utm.medium),
          (objTrack.utm_source = this.utm.source),
          (objTrack.utm_campaign = this.utm.campaign);
      }

      // Tracking for account or user creation
      this.$analytics.trackEvent(
        this.fromSignup ? "Account created" : "User created",
        objTrack
      );

      window.localStorage.removeItem("signupData");
    },
  },
};
</script>

<style lang="scss" scoped>
.account-setup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 20px;
  background: var(--color-powder);

  &__text {
    color: #071c4d;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 5px;

    &--first {
      opacity: 0;
      transform: translate(0, -100%);
      animation: first-text 6s cubic-bezier(0.52, -0.05, 0.12, 1.07) 1;
    }

    &--second {
      opacity: 1;
      transform: translate(0, -125%);
      animation: second-text 6s cubic-bezier(0.52, -0.05, 0.12, 1.07) 1;
    }
  }

  &__bar {
    position: relative;
    width: 170px;
    max-width: 100%;
    height: 2px;
    margin: 0 auto;
    border-radius: 20px;
    background: #d8dff2;

    &:after {
      content: "";
      position: absolute;
      top: -1px;
      left: 0;
      width: 97%;
      height: 4px;
      border-radius: 20px;
      background: #1258ff;
      animation: bar-progress 6s cubic-bezier(0.52, -0.05, 0.12, 1.07) 1;
    }
  }

  @keyframes bar-progress {
    0% {
      width: 2%;
    }
    25% {
      width: 20%;
    }
    50% {
      width: 70%;
    }
    100% {
      width: 97%;
    }
  }

  @keyframes first-text {
    0%,
    30% {
      opacity: 1;
      transform: translate(0, -20%);
    }
    50%,
    100% {
      opacity: 0;
      transform: translate(0, -100%);
    }
  }

  @keyframes second-text {
    0%,
    30% {
      opacity: 0;
      transform: translate(0, 0);
    }
    60%,
    100% {
      opacity: 1;
      transform: translate(0, -125%);
    }
  }
}
</style>
